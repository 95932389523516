import React, { memo } from 'react';
import { Link, graphql } from 'gatsby';
import { SEO, Card, ImageCard } from '@components';

import './blog.scss';
import { LandingTopAnimated } from '@containers';

const BlogPage = ({ data }) => {
  return (
    <div>
      <SEO title="Blog" />
      <LandingTopAnimated className="on-blog-animation"/>
      <div className="on-blog-main">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} className="on-blog-item-container">
            <Link className="on-blog-item" to={node.fields.slug}>
              <ImageCard
                className="on-blog-card"
                contentClassName="on-blog-dim-content"
                filename={node.frontmatter.frontImage}
              >
                <div className="on-blog-item-grid">
                  <h3>
                    {node.frontmatter.title} <span>— {node.frontmatter.date}</span>
                  </h3>
                  <p>{node.excerpt}</p>
                </div>
              </ImageCard>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    ...BlogQueryFragment
  }
`;

export default memo(BlogPage);
